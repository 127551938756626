// @ts-nocheck

// External imports
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ThemeProvider } from '@mui/material';

// Styles
import fontTheme from './styles/font';

// Component imports
import { AuthProvider } from './components/AuthContext';
import About from './components/About';
import EmailConfirmation from './components/EmailConfirmation';
import Home from './components/Home';
import Login from './components/Login';
import MySnacks from './components/MySnacks';
import NotFound from './components/NotFound';
import Profile from './components/Profile';
import SnackPage from './components/SnackPage';
import Snacks from './components/Snacks';
import Signup from './components/Signup';
import UserSettings from './components/UserSettings';
// import ForgotPassword from './components/ForgotPassword';

function App() {
  return (
    <ThemeProvider theme={fontTheme}>
      <AuthProvider>
        <Router>
          <>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/snacks" element={<Snacks />} />
              <Route path="/snackpage/:snackId" element={<SnackPage />} />
              <Route path="/login" element={<Login />} />
              <Route path="/signup" element={<Signup />} />
              {/* <Route path="/forgotpassword" element={<ForgotPassword />} /> */}
              <Route path="/usersettings" element={<UserSettings />} />
              {/* <Route path="/profile" element={<Profile />} /> */}
              <Route path='/mysnacks' element={<MySnacks />} />
              {/* <Route path="/emailconfirmation/:verificationToken" element={<EmailConfirmation />} /> */}
              <Route element={<NotFound />} />
            </Routes>
          </>
        </Router>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
