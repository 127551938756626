// @ts-nocheck

// External imports
import React, { createContext, useState, useEffect, useContext } from 'react';

// API
import apiUrl from '../apiUrl';

// Create a context for authentication
const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext); // Custom hook to access the context

export const AuthProvider = ({ children }) => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    const checkLoginStatus = async () => {
        try {
            const response = await fetch(`${apiUrl}/auth`, {
                method: 'GET',
                credentials: 'include', // Include cookies in the request
            });

            if (response.ok) {
                const data = await response.json();

                if (data.user) {
                    // The client is authenticated, handle logged-in state
                    setIsLoggedIn(true);
                } else if (!data.user) {
                    // The client is a visitor, handle visitor session state
                    setIsLoggedIn(false);
                }
            } else {
                console.error('Unexpected response from server', response.status);
                setIsLoggedIn(false); // Not authenticated
            }
        } catch (error) {
            console.error('Error during authentication:', error);
            setIsLoggedIn(false); // On error, assume not authenticated
        }
    };

    useEffect(() => {
        // Only run the check once when the component mounts
        checkLoginStatus();
    }, []);

    return (
        <AuthContext.Provider value={{ isLoggedIn, checkLoginStatus }}>
            {children}
        </AuthContext.Provider>
    );
};
