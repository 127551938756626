// External imports
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useAuth } from './AuthContext';

// Material UI components
import { Typography, Snackbar, Alert, Box, Button, Divider } from '@mui/material';

// Internal components
import NavigationBar from './NavigationBar';

// const listItemStyle = {
//   marginBottom: '8px',
//   borderRadius: '4px',
//   maxWidth: '500px',
//   border: '1px solid #2196f3', // Change the color as needed
//   '&:hover': {
//     backgroundColor: '#2196f3', // Change the hover background color
//     color: '#fff', // Change the hover text color
//     border: '1px solid transparent', // Hide the border on hover
//   },
// };

// Snackers logo that appears when screen width <600 px
// Commented out for now at 07.04.2024
// <Box
//   sx={{
//     display: { xs: 'flex', sm: 'none', md: 'none' }, // Hide on extra-small screens (xs) and display flex on small (sm) and up
//     alignItems: 'center',
//   }}
// >
//   <img src={snackersLogo} alt='Snackers logo' style={{ maxHeight: '60px', margin: '0 0 1rem -0.5rem' }} />
//   <Typography variant='h5' sx={{ height: '20px' }}>Snackers</Typography>
// </Box>

const Home = () => {
  const { t } = useTranslation();

  const [showLoginSuccessSnackbar, setShowLoginSuccessSnackbar] = useState(false);
  const [showLogoutSuccessSnackbar, setShowLogoutSuccessSnackbar] = useState(false);
  const [loginToChangePassword, setLoginToChangePassword] = useState(false);
  const { isLoggedIn } = useAuth(); // Access the login status from context

  useEffect(() => {
    if (sessionStorage.getItem('loginSuccess')) {
      setShowLoginSuccessSnackbar(true);
      sessionStorage.removeItem('loginSuccess')
    }

    if (sessionStorage.getItem('logoutSuccess')) {
      setShowLogoutSuccessSnackbar(true);
      sessionStorage.removeItem('logoutSuccess')
    }
  }, []);

  return (
    <div>
      <NavigationBar navigationBarData={{ homeComponent: '' }} />
      <div style={{ display: 'flex', justifyContent: 'center', padding: '1rem' }}>
        <div className='main-content'>

          {!isLoggedIn &&
            <Box sx={{ textAlign: 'center', border: '1px solid #ccc', borderRadius: '8px', padding: '1rem' }}>
              <Typography>{t('signup_box_header')}</Typography>
              <Typography sx={{ marginBottom: '1em' }}>{t('signup_box_text')}</Typography>
              <Button component={Link} to="/login" variant="outlined" sx={{ marginBottom: '1em' }}>{t('signup_box_button')}</Button>
              <Divider variant='middle' sx={{ marginBottom: '1em' }} />
              <Typography sx={{ marginBottom: '1em' }}>{t('view_snacks_box_text')}</Typography>
              <Button component={Link} to="/snacks" variant="outlined">{t('view_snacks_button')}</Button>
            </Box>
          }

          {isLoggedIn &&
            <Box sx={{ textAlign: 'center', border: '1px solid #ccc', borderRadius: '8px', padding: '1rem' }}>
              <Typography sx={{ marginBottom: '1em' }}>{t('logged_in_view_snacks_box_text')}</Typography>
              <Button component={Link} to="/snacks" variant="outlined" sx={{ marginBottom: '1em' }}>{t('logged_in_view_snacks_box_button')}</Button>
              <Divider variant='middle' sx={{ marginBottom: '1em' }} />
              <Typography sx={{ marginBottom: '1em' }}>{t('logged_in_view_my_snacks_box_text')}</Typography>
              <Button component={Link} to="/mysnacks" variant="outlined">{t('logged_in_view_my_snacks_box_button')}</Button>
            </Box>
          }

          <Snackbar open={showLoginSuccessSnackbar} autoHideDuration={3000} onClose={() => setShowLoginSuccessSnackbar(false)}>
            <Alert variant='filled' severity="success" sx={{ width: '100%' }}>
              {t('login_success_alert')}
            </Alert>
          </Snackbar>
          <Snackbar open={showLogoutSuccessSnackbar} autoHideDuration={3000} onClose={() => setShowLogoutSuccessSnackbar(false)}>
            <Alert variant='filled' severity="info" sx={{ width: '100%' }}>
              {t('logout_success_alert')}
            </Alert>
          </Snackbar>
          <Snackbar open={loginToChangePassword} autoHideDuration={3000} onClose={() => setLoginToChangePassword(false)}>
            <Alert variant='filled' severity="error" sx={{ width: '100%' }}>
              {t('login_to_change_password_alert')}
            </Alert>
          </Snackbar>
        </div>
      </div>
    </div>
  );
};

export default Home;
