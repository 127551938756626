// External imports
import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useNavigate } from "react-router-dom";

// Material UI components
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import CircularProgress from '@mui/material/CircularProgress';
import { Snackbar, Alert, FormHelperText } from '@mui/material';

// Internal components
import NavigationBar from './NavigationBar';

// API
import apiUrl from '../apiUrl';

export default function Signup() {
  const { t } = useTranslation();

  const dataToNavigationbar = {
    signupComponent: '',
  }

  const [loading, setLoading] = useState(false);
  const [signupFailure, setSignupFailure] = useState(false);

  const [formValues, setFormValues] = useState({ username: '', email: '', password: '', confirmPassword: '' });
  const [formErrors, setFormErrors] = useState({ username: '', email: '', password: '', confirmPassword: '' });

  const validateForm = () => {
    const errors = {
      username: '',
      email: '',
      password: '',
      confirmPassword: '',
    };

    if (formValues.username.trim() === '') {
      errors.username = t('username_error');
    }

    if (formValues.email.trim() !== '') {
      if (!formValues.email.match(/^[\p{L}\p{N}.-]+@[\p{L}\p{N}.-]+\.[\p{L}]{2,}$/u)) {
        errors.email = t('email_error');
      }
    }

    if (formValues.password.length < 8) {
      errors.password = t('password_error');
    }

    if (formValues.confirmPassword !== formValues.password) {
      errors.confirmPassword = t('password_again_error');
    }

    setFormErrors(errors);

    return Object.values(errors).every((error) => error === '');
  };

  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();

    setLoading(true);

    try {
      if (validateForm()) {
        const data = new FormData(event.currentTarget);

        const response = await fetch(`${apiUrl}/signup`, {
          method: 'POST',
          body: data,
        });

        if (response.ok) {
          sessionStorage.setItem('signupSuccess', 'true');
          setLoading(false);
          navigate('/login');
        } else {
          setSignupFailure(true);
          console.error('Error sending form data');
        }
      }
    } catch (error) {
      console.error('Error sending form data:', error);
    } finally {
      setLoading(false); // Set loading to false regardless of success/error
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      [name]: value,
    }));
  };

  return (
    <div>
      <NavigationBar navigationBarData={dataToNavigationbar} />
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              label={t('username_placeholder')}
              name="username"
              autoComplete="username"
              value={formValues.username}
              onChange={handleInputChange}
              error={!!formErrors.username}
              helperText={formErrors.username}
            />
            <FormHelperText>{t('username_info_text')}</FormHelperText>
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label={t('password_placeholder')}
              type="password"
              autoComplete="current-password"
              value={formValues.password}
              onChange={handleInputChange}
              error={!!formErrors.password}
              helperText={formErrors.password}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="confirmPassword"
              label={t('password_again_placeholder')}
              type="password"
              autoComplete="current-password"
              value={formValues.confirmPassword}
              onChange={handleInputChange}
              error={!!formErrors.confirmPassword}
              helperText={formErrors.confirmPassword}
            />
            <TextField
              margin="normal"
              fullWidth
              label={t('email_placeholder')}
              name="email"
              autoComplete="email"
              value={formValues.email}
              onChange={handleInputChange}
              error={!!formErrors.email}
              helperText={formErrors.email}
            />
            <FormHelperText>{t('email_info_text')}</FormHelperText>
            {loading ? (
              <Button
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                <CircularProgress color="inherit" />
              </Button>
            ) : (
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                {t('signup_button')}
              </Button>
            )}
          </Box>
        </Box>
        <Snackbar open={signupFailure} autoHideDuration={3000} onClose={() => setSignupFailure(false)}>
          <Alert variant='filled' severity="error" sx={{ width: '100%' }}>
            {t('signup_failure_alert')}
          </Alert>
        </Snackbar>
      </Container>
    </div>
  );
}
