import { createTheme } from '@mui/material/styles';

const fontTheme = createTheme({
  typography: {
    fontFamily: [
      'Montserrat',
    ].join(','),
  },
});

export default fontTheme;
